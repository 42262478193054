import { BFStorage } from '@integration-frontends/core/storage';

export class BFStorageMock implements BFStorage {
    getItem(key: string): Promise<any> {
        return null;
    }
    setItem(key: string, value: string): Promise<any> {
        return null;
    }
    removeItem(key: string): Promise<any> {
        return null;
    }
    clear(): void {
        return;
    }
    key(index: number): string | null {
        return null;
    }
    get length(): number {
        return 0;
    }
    [key: string]: any;
    [index: number]: string;
}
