import { Environment } from '@integration-frontends/apps/common/utils/environment-types';
const hostUrl = 'https://workflow-manager-stage.brandfolder-svc.com';

export const environment = {
  production: false,
  segmentWriteKey: '3D2vwp7cRNdeQQAR0agYzuEH3oAi3XWA',
  bfBaseUrl: 'https://bf-stage.com/api',
  bfWebsiteBaseUrl: 'https://bf-stage.com',
  bfStaticAssetsBaseUrl: 'https://static-fast.bf-stage.com',
  hostUrl,
  oauthBaseUrl: 'https://oauth2.stage.integration.bf-squads.com',
  oauthClientId: 'workflow-manager-client-stage',
  temporalBaseUrl: 'https://integration-workflows-stage.brandfolder-svc.com/api',
  oauthRedirectUri: hostUrl,
  environment: Environment.Stage,
};
